import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import SelectBox from "../../presentational_components/SelectBox";
import AbstractQuestion from "../question_components/AbstractQuestion";
import ErrorFeedback from "../../presentational_components/ErrorFeedback";

const AnswersContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 680px;
`;

const days = Array(31)
  .fill(1)
  .map((x, y) => x + y);

var dayOptions = days.reduce(function(map, obj) {
  map.push({ label: obj, value: obj });
  return map;
}, []);

const monthOptions = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 }
];

const years = Array(100)
  .fill(new Date().getFullYear() - 99)
  .map((x, y) => x + y)
  .reverse();

var yearOptions = years.reduce(function(map, obj) {
  map.push({ label: obj, value: obj });
  return map;
}, []);

class DateOfBirth extends React.Component {
  constructor(props) {
    super(props);
    const { values } = this.props;
    this.state = {
      selectValues: {
        day: values.dob_day
          ? dayOptions.find(day => day.value == values.dob_day)
          : null,
        month: values.dob_month
          ? monthOptions.find(month => month.value == values.dob_month)
          : null,
        year: values.dob_year
          ? yearOptions.find(year => year.value == values.dob_year)
          : null
      }
    };
  }

  handleChange = (selectedOption, type) => {
    this.setState({
      selectValues: { ...this.state.selectValues, [type]: selectedOption.value }
    });
  };

  renderSelectInputs() {
    const { selectValues } = this.state;
    const fieldPrefix = this.props.fieldPrefix || ""

    return (
      <AnswersContainer>
        <SelectBox
          isSearchable={false}
          onChange={selectedOption => this.handleChange(selectedOption, "day")}
          options={dayOptions}
          placeholder={"Date"}
          name={`${this.props.formName}[${fieldPrefix}dob_day]`}
          id={`${this.props.formName}_${fieldPrefix}dob_day`}
          classNamePrefix={"react-select"}
          defaultValue={selectValues.day}
          aria-label={"Date"}
        />

        <SelectBox
          isSearchable={false}
          type={"month"}
          onChange={selectedOption =>
            this.handleChange(selectedOption, "month")
          }
          options={monthOptions}
          placeholder={"Month"}
          name={`${this.props.formName}[${fieldPrefix}dob_month]`}
          id={`${this.props.formName}_${fieldPrefix}dob_month`}
          classNamePrefix={"react-select"}
          defaultValue={selectValues.month}
          aria-label={"Month"}
        />

        <SelectBox
          isSearchable={false}
          onChange={selectedOption => this.handleChange(selectedOption, "year")}
          options={yearOptions}
          placeholder={"Year"}
          name={`${this.props.formName}[${fieldPrefix}dob_year]`}
          id={`${this.props.formName}_${fieldPrefix}dob_year`}
          classNamePrefix={"react-select"}
          defaultValue={selectValues.year}
          aria-label={"Year"}
        />
      </AnswersContainer>
    );
  }

  render() {
    const { selectValues } = this.state;
    const canProgress = Object.keys(selectValues).every(i => selectValues[i]);
    return (
      <>
      <AbstractQuestion
        {...this.props}
        handleSubmit={this.props.handleSubmit}
        canProgress={canProgress}
      >
        {this.renderSelectInputs()}

        {this.props.error && (<ErrorFeedback message={this.props.error}/>)}
      </AbstractQuestion>
      </>
    );
  }
}

DateOfBirth.propTypes = {
  formName: PropTypes.string.isRequired,
  fieldPrefix: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object
};

export default DateOfBirth;
